@use '@angular/material' as mat;
@use '@angular/material-experimental' as matx;
@import '/src/assets/fonts/nunito-sans/nunito-sans.scss';


$theme: matx.define-theme((
  color: (
    theme-type: light,
    primary: matx.$m3-azure-palette,
    tertiary: matx.$m3-blue-palette,
  )
));
 
@include mat.core();
@include matx.color-variants-back-compat($theme);
 
:root {
  @include mat.all-component-themes($theme);
}

:root .lpx-theme-dark {
  --lpx-logo: url('/assets/images/logo/logo-light.svg');
  --lpx-logo-icon: url('/assets/images/logo/icon.svg');
}

:root .lpx-theme-light {
  --lpx-logo: url('/assets/images/logo/logo-dark.svg');
  --lpx-logo-icon: url('/assets/images/logo/icon.svg');
}
:root{
  --lpx-theme-light-bg: url('/assets/images/login/login-bg-img-light.svg');
  --lpx-theme-dim-bg: url('/assets/images/login/login-bg-img-dim.svg');
  --lpx-theme-dark-bg: url('/assets/images/login/login-bg-img-dark.svg');
}

abp-logo{
  width: 15%;
}

.navbar-menu {
  justify-content: space-between;
}

 
