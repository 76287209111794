@font-face {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 200;
  src: url('NunitoSans-ExtraLight.ttf');
}

@font-face {
  font-family: Nunito Sans;
  font-style: italic;
  font-weight: 200;
  src: url('NunitoSans-ExtraLightItalic.ttf');
}

@font-face {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 300;
  src: url('NunitoSans-Light.ttf');
}

@font-face {
  font-family: Nunito Sans;
  font-style: italic;
  font-weight: 300;
  src: url('NunitoSans-LightItalic.ttf');
}

@font-face {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 400;
  src: url('NunitoSans-Regular.ttf');
}

@font-face {
  font-family: Nunito Sans;
  font-style: italic;
  font-weight: 400;
  src: url('NunitoSans-Italic.ttf');
}

@font-face {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 500;
  src: url('NunitoSans-SemiBold.ttf');
}

@font-face {
  font-family: Nunito Sans;
  font-style: italic;
  font-weight: 500;
  src: url('NunitoSans-SemiBoldItalic.ttf');
}

@font-face {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  src: url('NunitoSans-Bold.ttf');
}

@font-face {
  font-family: Nunito Sans;
  font-style: italic;
  font-weight: 600;
  src: url('NunitoSans-BoldItalic.ttf');
}

@font-face {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 700;
  src: url('NunitoSans-ExtraBold.ttf');
}

@font-face {
  font-family: Nunito Sans;
  font-style: italic;
  font-weight: 700;
  src: url('NunitoSans-ExtraBoldItalic.ttf');
}

@font-face {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 800;
  src: url('NunitoSans-Black.ttf');
}

@font-face {
  font-family: Nunito Sans;
  font-style: italic;
  font-weight: 800;
  src: url('NunitoSans-BlackItalic.ttf');
}

body {
  font-family: Nunito Sans;
  font-weight: 400;
  font-size: 14px;
}

.btn,
.lp-sidebar a,
.navbar-brand,
header a {
  font-family: Nunito Sans;
  font-weight: 400;
}

body .h1,
body .h2,
body .h3,
body .h4,
body .h5,
body .h6,
body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  font-family: Nunito Sans;
  font-weight: 600;
  line-height: 1.5;
}

body .ui-table .ui-editable-column input {
  font-family: Nunito Sans;
  font-size: 14px;
}

div.tooltip-inner {
  font-family: Nunito Sans;
}
